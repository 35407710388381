import {EventsDates, RsvpStatus} from '@wix/events-types'
import {ControllerParams} from '@wix/yoshi-flow-editor'
import {Api as BaseApi} from '../../../../commons/utils/api'
import {getComponentData} from '../../shared-api'
import {getEvents} from '../actions/events'
import {CANCEL_RSVP, DELETE_RSVP} from '../actions/rsvp'
import {GET_MY_TICKETS} from '../actions/tickets'

export class Api extends BaseApi {
  constructor(controllerParams: ControllerParams) {
    super(controllerParams)

    this.registrar = {
      [getEvents.typePrefix]: this.getEvents,
      [DELETE_RSVP.NAME]: this.deleteRsvp,
      [CANCEL_RSVP.NAME]: this.cancelRsvp,
      [GET_MY_TICKETS.NAME]: this.getMyTickets,
    }
  }

  getEvents = ({
    memberId,
    statuses,
    extended,
    offset = 0,
    locale,
  }: {
    memberId: string
    statuses: wix.events.EventStatus[]
    extended: boolean
    offset: number
    locale: string
  }): Promise<{
    events: wix.events.Event[]
    orders: wix.events.ticketing.Order[]
    rsvps: wix.events.rsvp.Rsvp[]
    total: number
    dates: {events: EventsDates}
  }> =>
    this.api.get(
      `/web/member-events?memberId=${memberId}&instance=${this.getInstance()}&extended=${extended}&offset=${offset}&locale=${locale}${statuses
        .map(status => `&status=${status}`)
        .join('')}`,
    )

  getComponentData = getComponentData(this.api)

  deleteRsvp = ({eventId, rsvpId}: {eventId: string; rsvpId: string}) =>
    this.api.delete(`/web/events/${eventId}/rsvp/${rsvpId}`).then(() => ({eventId}))

  cancelRsvp = ({eventId, rsvpId}: {eventId: string; rsvpId: string}) =>
    this.api.put(`/web/events/${eventId}/rsvp/${rsvpId}/status`, {status: RsvpStatus.NO}).then(() => ({eventId}))

  getMyTickets = (eventId: string, memberId: string) =>
    this.api.get(`/web/events/${eventId}/my-tickets?memberId=${memberId}`)
}
